export default {
  namespaced: true,
  state: {
    mainTopic:null,
    subTopic:null,
    courierTopic:null,
    sellerTopic:null
  },
  getters: {
    
  },
  mutations: {
    SET_MAIN_TOPIC(state, val) {
        state.mainTopic = val
    },
    SET_SUB_TOPIC(state, val) {
        state.subTopic = val
    },
    SET_COURIER_TOPIC(state, val) {
        state.courierTopic = val
    },
    SET_SELLER_TOPIC(state, val) {
      state.sellerTopic = val
  },
  },
  actions: {},
}
