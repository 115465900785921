export default [
   
    {
        path: '/admin-settings/add-ads',
        name: 'add-ads',
        component: () => import('@/views/admin-settings/AddAds.vue'),
        meta: {
            pageTitle: 'Add Ads',
           
        },
    },
    {
        path: '/admin-settings/update-ads',
        name: 'update-ads',
        component: () => import('@/views/admin-settings/UpdateAds.vue'),
        meta: {
            pageTitle: 'Update Ads',
            
        }, 
    },
    {
        path: '/admin-settings/seller-customer-intimation-events',
        name: 'seller-customer-intimation-events',
        component: () => import('@/views/admin-settings/SellerIntimationEvents.vue'),
        meta: {
            pageTitle: 'Seller Customer Intimation Events',
           
        },
    },
   
]
