export default [
    {
        path: '/seller-settings/approve-accounts',
        name: 'approve-accounts',
        component: () => import('@/views/seller-settings/ApproveAccounts.vue'),
        meta: {
            pageTitle: 'Approve Seller Acounts',
           
        },
    },
    {
        path: '/seller-settings/seller-billing',
        name: 'seller-billing',
        component: () => import('@/views/seller-settings/Billing.vue'),
        meta: {
            pageTitle: 'Seller Billing',
           
        },
    },
    {
        path: '/seller-settings/manual-topup',
        name: 'manual-topup',
        component: () => import('@/views/seller-settings/Topup.vue'),
        meta: {
            pageTitle: 'Manual Wallet Topup',
           
        },
    },
    {
        path: '/seller-settings/assign-packages',
        name: 'assign-packages',
        component: () => import('@/views/seller-settings/AssignPackage.vue'),
        meta: {
            pageTitle: 'Assign Subscription Packages',
           
        },
    },
    {
        path: '/seller-settings/refund-charges',
        name: 'refund-charges',
        component: () => import('@/views/seller-settings/RefundCharges.vue'),
        meta: {
            pageTitle: 'Refund Shipping Charges',
           
        },
    },
    {
        path: '/seller-settings/universal-courier-accounts-approval',
        name: 'universal-courier-accounts-approval',
        component: () => import('@/views/seller-settings/UniversalCourierAccountsApproval.vue'),
        meta: {
            pageTitle: 'Universal Courier Accounts Approvals',
           
        },
    },
]
