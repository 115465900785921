export default [
    {
        path: '/universal-payments/courier-payments',
        name: 'universal-courier-payments',
        component: () => import('@/views/payments/CourierPayment.vue'),
        meta: {
            pageTitle: 'Courier Payment',
            breadcrumb: [
                {
                    text: 'Table',
                },
                {
                    text: 'Courier Payments',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/universal-payments/upload-charges',
        name: 'upload-courier-charges',
        component: () => import('@/views/payments/UploadCharges.vue'),
        meta: {
            pageTitle: 'Upload Courier Charges',
           
        },
    },
    {
        path: '/universal-payments/seller-payments',
        name: 'seller-payments',
        component: () => import('@/views/payments/SellerPayment.vue'),
        meta: {
            pageTitle: 'Seller Payment Master',
           
        },
    },
    {
        path: '/reports/courier-receivables-aging',
        name: 'courier-receivables-aging',
        component: () => import('@/views/reports/CourierReceivablesAging.vue'),
        meta: {
            pageTitle: 'Courier Receivables Aging',
            
        },
    },
    {
        path: '/reports/drilldown-report',
        name: 'drilldown-report',
        component: () => import('@/views/reports/DrillDownReport.vue'),
        meta: {
            pageTitle: 'DrillDown Report',
            
        },
    },
    {
        path: '/reports/discrepancy-report',
        name: 'discrepancy-report',
        component: () => import('@/views/reports/DiscrepancyReport.vue'),
        meta: {
            pageTitle: 'Discrepancy Report',
            
        },
    },
    {
        path: '/universal-payments/seller-invoices',
        name: 'seller-invoices',
        component: () => import('@/views/payments/SellerInvoices.vue'),
        meta: {
            pageTitle: 'Settle Payment Invoices',
           
        },
    },
    {
        path: '/universal-payments/reconciled-payments',
        name: 'reconciled-payments',
        component: () => import('@/views/payments/ReconciledPayments.vue'),
        meta: {
            pageTitle: 'Reconciled Payments',
           
        },
    },
    {
        path: '/universal-payments/courier-invoices',
        name: 'courier-invoices',
        component: () => import('@/views/payments/CourierInvoices.vue'),
        meta: {
            pageTitle: 'Non-Reconciled Payments',
           
        },
    },
    {
        path: '/universal-payments/courier-invoice-detail',
        name: 'courier-invoice-detail',
        component: () => import('@/views/payments/CourierInvoiceDetail.vue'),
        meta: {
            pageTitle: 'Courier Payment Invoice Detail',
           
        },
    },
]
